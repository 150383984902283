import React, { memo, useMemo } from 'react'
import isNumber from 'lodash/isNumber'
import forEach from 'lodash/forEach'
import range from 'lodash/range'
import isEmpty from 'lodash/isEmpty'
import {
  CartesianGrid,
  Line,
  Area,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new'
import { representationAsNTD } from '~/utils/representationAsNTD'
import { globalGrey, globalGreen, globalRed } from '~/modules/AppLayout/Colors'
import useMedia from '~/hooks/useMedia'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { isCallOption, isOpbsSymbol } from '~/utils/optionsTranslator'
import getSymbolPrice from '~/modules/options/utils/getSymbolPrice'
import { useOpbsProcessingState } from '~/modules/options/shareContainers/useOpbsProcessingState'

import {
  decomposeSymbol,
  decomposeQty,
  decomposePrice,
} from '~/modules/virtual-exchange-center/utils/opbsOrderUtils'

const OUTLIER = 1000

type ChartDatum = {
  name: string
  profitability: number
}

const UnrealizeVirtualPlChart = memo(function UnrealizeVirtualPlChart(props) {
  const { isPhone } = useMedia()
  const { state, properties } = useOpbsProcessingState.useContainer()

  // 由大到小的履約價格陣列， 19000, 17500, ... , 16000
  const strikePrices = properties.strikePrices
  const atTheMoneyIndex = state.atTheMoneyIndex
  const atTheMoneyPrice = state.atTheMoneyPrice

  const txoPositions = useTradeStore(s => s.positions)
    .filter(p => isOpbsSymbol(p.symbol))
    .map(origin =>
      // 分解複式單
      decomposeSymbol(origin.symbol).map((newChild, index) => ({
        ...origin,
        symbol: newChild,
        qty: decomposeQty(origin.symbol, newChild, origin.qty, index === 0),
        averagePrice: decomposePrice(origin.symbol, origin.averagePrice, index === 0),
      })),
    )
    .flat()

  const extendStrikePrices = useMemo(() => {
    if (isEmpty(strikePrices)) return []
    const extendCount = 6
    const step = Math.min(
      ...strikePrices
        .map((price, index) =>
          index === strikePrices.length - 1 ? OUTLIER : price - strikePrices[index + 1],
        )
        .filter(price => price > 0),
    )

    /** 取得價平之 正負600 區間 */
    const rangeStart = atTheMoneyPrice + 600 // 大
    const rangeEnd = atTheMoneyPrice - 600 // 小

    if (rangeStart && rangeEnd)
      return range(rangeEnd - extendCount * step, rangeStart + extendCount * step, step)

    return []
  }, [strikePrices, atTheMoneyPrice])

  /** 目前持有的部位去計算預期價格 */
  const data = useMemo(() => {
    return extendStrikePrices.map(strikePrice => {
      let expectedProfitability = 0
      forEach(txoPositions, datum => {
        let intrinsicValue = 0
        const isCall = isCallOption(datum.symbol)
        const positionStrikePrice = getSymbolPrice(datum.symbol) ?? 0
        if (isCall) {
          intrinsicValue = Math.max(strikePrice - positionStrikePrice, 0) * datum.qty
        } else {
          intrinsicValue = Math.max(positionStrikePrice - strikePrice, 0) * datum.qty
        }

        expectedProfitability += intrinsicValue + Math.abs(datum.averagePrice) * datum.qty * -1
      })

      const returnDatum: ChartDatum = {
        name: strikePrice.toString(),
        profitability: (expectedProfitability * 50) / 10000,
      }

      return returnDatum
    })
  }, [extendStrikePrices, txoPositions])

  if (isEmpty(data)) return null
  const profitabilityKey: keyof ChartDatum = 'profitability'

  const max = data.reduce((prev, current) =>
    prev.profitability > current.profitability ? prev : current,
  )
  const maxProfitPrice = parseInt(max.name)

  const min = data.reduce((prev, current) =>
    prev.profitability < current.profitability ? prev : current,
  )

  const domain: [number, number] =
    Math.abs(min.profitability) > Math.abs(max.profitability)
      ? [min.profitability, (min.profitability * -1) / 3]
      : [max.profitability * -1, max.profitability / 3]

  const meaningfulTicks = [
    ...domain.map(d => d * 1.2),
    0,
    (domain[0] / 3) * 1,
    (domain[0] / 3) * 2,
    max.profitability,
  ]
    // 變成1000 整數倍數值
    .map(d => Math.round(d / 1000) * 1000)
    .sort((a, b) => a - b)

  const targetColor =
    maxProfitPrice == atTheMoneyPrice
      ? globalGrey.g700
      : maxProfitPrice >= atTheMoneyPrice
      ? globalRed.r500
      : globalGreen.g500

  const CustomizedLabel = (
    <Label
      position='top'
      value={max.name}
      fill={targetColor}
      fontSize={'1.2rem'}
      offset={10}
    />
  )

  const gradientOffset = () => {
    const dataMax = Math.max(...data.map(i => i.profitability))
    const dataMin = Math.min(...data.map(i => i.profitability))

    if (dataMax <= 0) {
      return 0
    }
    if (dataMin >= 0) {
      return 1
    }

    return dataMax / (dataMax - dataMin)
  }

  const off = gradientOffset()

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        margin={{ right: 32, bottom: 8, top: 40 }}
      >
        <CartesianGrid strokeDasharray='8 4' />
        <XAxis dataKey='name' />
        <Tooltip
          formatter={(value: string | number, name: string) => {
            if (isNumber(value)) {
              return [
                representationAsNTD(value * 10000),
                (name === profitabilityKey ? '預期' : '留倉') + '損益',
              ]
            }

            return [value, name]
          }}
        />

        <ReferenceLine
          y={0}
          stroke={globalGrey.g900}
          strokeWidth={2}
        />
        <ReferenceLine
          y={max.profitability}
          stroke={globalGrey.g700}
        />
        <ReferenceLine
          x={max.name}
          stroke={targetColor}
          strokeWidth={2}
          label={CustomizedLabel}
        />
        <YAxis
          domain={domain}
          ticks={meaningfulTicks}
          mirror={isPhone}
          orientation='left'
        />

        <defs>
          <linearGradient
            id='splitColor'
            x1='0'
            y1='0'
            x2='0'
            y2='1'
          >
            <stop
              offset={off}
              stopColor={globalRed.r500}
              stopOpacity={1}
            />
            <stop
              offset={off}
              stopColor={globalGreen.g500}
              stopOpacity={1}
            />
          </linearGradient>
        </defs>
        <Area
          type='monotone'
          dataKey={profitabilityKey}
          stroke='#000'
          fill='url(#splitColor)'
          opacity={0.4}
          legendType='none'
          tooltipType='none'
        />

        <Line
          isAnimationActive={false}
          type='monotone'
          stroke='#7070db'
          dataKey={profitabilityKey}
          strokeWidth={3}
          dot={{ r: 4 }}
          opacity={0.75}
        />
        {atTheMoneyIndex !== -1 && (
          <ReferenceLine
            x={atTheMoneyPrice}
            strokeWidth={2}
            stroke={globalGrey.g700}
            strokeDasharray='8 4'
            label='- - 價 平 - -'
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
})
export default UnrealizeVirtualPlChart
