import { memo, useEffect } from 'react'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'

import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

const OrderTypeToggle = memo(function OrderTypeToggle() {
  const { state, acts } = useVirtualExchangeCenterParametersState.useContainer()

  useEffect(() => {
    if (state.isCompoundOption) {
      acts.setOrderType('IOC')
    }
  }, [state.isCompoundOption, acts])

  return (
    <ToggleButtonGroup
      size='small'
      disabled={state.isCompoundOption}
    >
      {state.isCompoundOption ? null : (
        <ToggleButton
          value={'ROD'}
          onClick={() => {
            acts.setOrderType('ROD')
          }}
          selected={'ROD' === state.orderType}
        >
          ROD
        </ToggleButton>
      )}
      <ToggleButton
        value={'IOC'}
        onClick={() => {
          acts.setOrderType('IOC')
        }}
        selected={'IOC' === state.orderType}
      >
        IOC
      </ToggleButton>
    </ToggleButtonGroup>
  )
})

export default OrderTypeToggle
