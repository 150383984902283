import { globalRed, globalGrey, globalGreen } from '~/modules/AppLayout/Colors'

export const getDeltaColor = (closePercentage: number) => {
  const color = ''

  if (closePercentage > 0) {
    if (closePercentage > 2) return globalRed.r700
    if (closePercentage > 1) return globalRed.r600
    if (closePercentage > 0.3) return globalRed.r200
  } else {
    if (closePercentage < -2) return globalGreen.gA700
    if (closePercentage < -1) return globalGrey.g600
    if (closePercentage < -0.3) return globalGreen.g200
  }

  return color
}
