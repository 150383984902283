import React, { memo, useCallback } from 'react'
import { css } from '@emotion/react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material'
import type { Signalr } from '~/modules/SDK/Signalr/Signalr'
import type { Option } from '~/modules/options/utils/Option'
import { useLifecycles } from 'react-use'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { globalOrange } from '~/modules/AppLayout/Colors'

import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

const OptionDetailsTable = memo<
  ReactProps<{
    data?: Option.ExtendValueOfOHLC[]
    isMirror?: boolean
    atTheMoneyIndex: number
  }>
>(function OptionDetailsTable({ data, isMirror, atTheMoneyIndex }) {
  return (
    <Table
      stickyHeader
      css={css`
        ${smallTableCss};
        .${tableRowClasses.root}:nth-of-type(${atTheMoneyIndex + 1}) {
          background: ${globalOrange.o100};
        }
      `}
    >
      <TableHead>
        <TableRow>
          {isMirror ? (
            <>
              <TableCell>未平倉量</TableCell>
              <TableCell>成交量</TableCell>
              <TableCell>時間價值</TableCell>
              <TableCell>漲跌</TableCell>
              <TableCell>成交價</TableCell>
            </>
          ) : (
            <>
              <TableCell>成交價</TableCell>
              <TableCell>漲跌</TableCell>
              <TableCell>時間價值</TableCell>
              <TableCell>成交量</TableCell>
              <TableCell>未平倉量</TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map(datum => (
          <TableRow
            key={datum.symbol}
            css={css`
              cursor: pointer;
            `}
          >
            <Item_
              symbol={datum.symbol}
              datum={datum}
              isMirror={isMirror}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const smallTableCss = css`
  .${tableCellClasses.root}.${tableCellClasses.head} {
    text-align: center;
    padding: 0px;
  }
  .${tableCellClasses.root}.${tableCellClasses.body} {
    text-align: center;
    padding: 2px 8px;
  }
  margin: 8px 0 32px;
`

export const Item_ = memo<
  ReactProps<{
    symbol: Signalr.SymbolString
    datum: Option.ExtendValueOfOHLC
    isMirror?: boolean
  }>
>(function Item_({ symbol, datum, isMirror }) {
  const { acts } = useVirtualExchangeCenterParametersState.useContainer()
  useLifecycles(
    () => {
      useSignalrStore.getState().subscribeAdd([symbol], 'ohlc')
    },
    () => {
      useSignalrStore.getState().subscribeRemove([symbol], 'ohlc')
    },
  )

  const isEmptyData = datum.openInterest === 0 && datum.volume === 0 && datum.close === 0
  const handleSelect = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement>) => {
      if (isEmptyData) return
      acts.handleOpenForm(e, symbol, true)
    },
    [acts, symbol, isEmptyData],
  )

  const delta = datum.close - datum.prevRef
  const deltaCss = css`
    color: ${delta > 0 ? 'red' : delta == 0 ? 'grey' : 'green'};
  `
  return (
    <>
      {isMirror ? (
        <>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.openInterest}</TableCell>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.volume}</TableCell>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.timeValue}</TableCell>
          <TableCell
            css={deltaCss}
            onClick={handleSelect}
          >
            {isEmptyData ? '-' : (delta > 0 ? '\u25B2' : '\u25BC') + delta.toFixed(2)}
          </TableCell>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.close}</TableCell>
        </>
      ) : (
        <>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.close}</TableCell>
          <TableCell
            css={deltaCss}
            onClick={handleSelect}
          >
            {isEmptyData ? '-' : (delta > 0 ? '\u25B2' : '\u25BC') + delta.toFixed(2)}
          </TableCell>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.timeValue}</TableCell>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.volume}</TableCell>
          <TableCell onClick={handleSelect}>{isEmptyData ? '-' : datum.openInterest}</TableCell>
        </>
      )}
    </>
  )
})

/** 期貨 指標監控定義色 */
export const getFuturesPercentageDeltaColor = (closePercentage: number) => {
  const color = '#fff'

  if (closePercentage > 0) {
    if (closePercentage > 100) return '#f63538'
    if (closePercentage > 40) return '#bf4045'
    if (closePercentage > 0.3) return '#8b444e'
  } else {
    if (closePercentage < -100) return '#30cc5a'
    if (closePercentage < -40) return '#2f9e4f'
    if (closePercentage < -0.3) return '#35764e'
  }

  return color
}

export default OptionDetailsTable
