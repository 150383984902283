import React, { memo, useCallback, useMemo } from 'react'
import { css } from '@emotion/react'
import { Paper } from '@mui/material'
import useMedia from '~/hooks/useMedia'
import { ai, jc, flex } from '~/modules/AppLayout/FlexGridCss'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useOsFuturesList } from '~/modules/SDK/Symbol/useCommonSymbolList'
import { useLifecycles } from 'react-use'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { OsFuturesList } from '~/modules/SDK/Symbol/useCommonSymbolListStore'
import { fontWeight600 } from '~/css/font'
import { getDeltaColor } from '~/modules/virtual-exchange-center/utils/getDeltaColors'
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

export const FuturesWrapper = memo(function FuturesWrapper() {
  const { isPc } = useMedia()

  const list = useOsFuturesList() as OsFuturesList
  const osSymbols = useMemo(
    () => [...list.overall, ...list.agriculture, ...list.energy, ...list.metal],
    [list.agriculture, list.metal, list.energy, list.overall],
  )

  return (
    <div
      css={css`
        ${isPc ? flex.wrap.default : flex.wrap.default};
        width: 100%;
        height: 40vh;
        overflow-y: auto;
        align-content: flex-start;
        padding: 16px 0;
        & > * {
          width: 160px;
          height: 48px;
          margin: 2px 4px;
        }
      `}
    >
      {osSymbols?.map(os => (
        <Item_
          key={os}
          symbol={os}
        />
      ))}
    </div>
  )
})

export const Item_ = memo<
  ReactProps<{
    symbol: Signalr.SymbolString
    key: Signalr.SymbolString
  }>
>(function Item_({ symbol }) {
  const { acts } = useVirtualExchangeCenterParametersState.useContainer()
  const dictionary = useSymbolDictionaryStore(s => s.dictionary)
  useLifecycles(
    () => {
      useSignalrStore.getState().subscribeAdd([symbol], 'ohlc')
    },
    () => {
      useSignalrStore.getState().subscribeRemove([symbol], 'ohlc')
    },
  )
  const symbolInfo = useSignalrStoreValueOHLC(s => s.value[symbol])
  const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
  const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close)

  const handleSelectSymbol = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      acts.handleOpenForm(e, symbol)
    },
    [acts, symbol],
  )

  return (
    <Paper
      elevation={2}
      css={css`
        cursor: pointer;
        ${quoteAnimation}99;
        color: ${getDeltaColor(quoteChanges?.closeChangePercent)};
      `}
    >
      <div
        onClick={handleSelectSymbol}
        css={css`
          ${flex.h.crossCenter};
          ${jc.spaceAround};
          * {
            margin: 0;
          }
          & > *:nth-of-type(1) {
            font-size: 1rem;
            ${fontWeight600};
          }
          & > *:nth-of-type(2) {
            font-size: 1rem;
          }
        `}
      >
        <p>{dictionary[symbol]}</p>
        <p>{symbolInfo?.close ? '$' + symbolInfo?.close : '-'}</p>
      </div>
      <div
        onClick={handleSelectSymbol}
        css={css`
          ${flex.h.default};
          ${jc.flexEnd};
          ${ai.flexEnd};
          padding: 0 16px;
          * {
            margin: 0;
            font-size: 1rem;
          }
          & > *:nth-of-type(2) {
            font-size: 0.8rem;
            margin-bottom: 2px;
          }
        `}
      >
        {symbolInfo ? (
          <>
            <p>{quoteChanges.deltaChangeString}</p>
            <p> &nbsp;({quoteChanges.closeChangePercentString})</p>
          </>
        ) : (
          '-'
        )}
      </div>
    </Paper>
  )
})
