import useSWR from 'swr-0-5-6'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'
import type { Option } from '~/modules/options/utils/Option'

const taifexOptionsOiURL = apirc.unclassified.taifexOptionsOiURL.baseUrl

/**
 * @param date 2021-11-11
 * @returns 目前可用合約、所有履約價格的未平倉量
 */
const useTaifexOptionsOiResource = (date: string | undefined) => {
  const swr = useSWR<Option.TaifexOptionsOiRemoteData[]>(urlcat(taifexOptionsOiURL, { date }))
  const data = swr.data
  return data
}

export default useTaifexOptionsOiResource
