import { css } from '@emotion/react'
import { TextField, ToggleButtonGroup, ToggleButton } from '@mui/material'
import React, { memo } from 'react'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'

import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

const PriceSettingField = memo(function PriceSettingField() {
  const {
    state: { currentSymbol: symbol },
  } = useVirtualExchangeCenterParametersState.useContainer()
  const price = useTradeStore(s => s.price)
  const priceCurrentGet = useSignalrStoreValueOHLC(state => state.value[symbol])?.close
  const { state, acts } = useVirtualExchangeCenterParametersState.useContainer()

  return (
    <>
      <ToggleButtonGroup size='small'>
        <ToggleButton
          value={'M'}
          onClick={() => {
            acts.setPriceType('M')
            useTradeStore.setState({ price: 'M' })
          }}
          selected={'M' === state.priceType}
        >
          市價
        </ToggleButton>
        <ToggleButton
          value={'limit'}
          onClick={() => {
            acts.setPriceType('limit')
            if (state.isOffsetMode && !priceCurrentGet) {
              useTradeStore.setState({ price: state.tempOffsetPrice.toString() })
            } else useTradeStore.setState({ price: String(priceCurrentGet) })
          }}
          selected={'limit' === state.priceType}
        >
          限價
        </ToggleButton>
      </ToggleButtonGroup>
      <TextField
        css={css`
          width: 120px;
        `}
        size='small'
        label='價格'
        value={price === 'M' ? '市價' : price}
        onChange={event => {
          if (state.priceType !== 'M') useTradeStore.setState({ price: event.target.value })
        }}
      />
    </>
  )
})

export default PriceSettingField
