import { css } from '@emotion/react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { memo } from 'react'

import {
  ModuleType,
  useVirtualExchangeCenterParametersState,
} from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

export const ModuleSelect = memo(function ModuleSelect() {
  const { state, acts } = useVirtualExchangeCenterParametersState.useContainer()
  return (
    <FormControl
      size='small'
      variant='outlined'
      css={css`
        width: 140px;
      `}
    >
      <InputLabel>市場別</InputLabel>
      <Select
        value={state.module}
        onChange={e => {
          acts.setModule(e.target.value as ModuleType)
          acts.setFormAnchorEl(null)
        }}
        label={'市場別'}
      >
        {state.isUserQualified && <MenuItem value={'self'}>自選</MenuItem>}
        <MenuItem value={'osfutures'}>期貨</MenuItem>
        <MenuItem value={'stock'}>台灣上市櫃</MenuItem>
        <MenuItem value={'opbs'}>選擇權</MenuItem>
      </Select>
    </FormControl>
  )
})
