import { memo, useMemo } from 'react'
import { css } from '@emotion/react'
import { Chip, chipClasses } from '@mui/material'
import { useOpbsProcessingState } from '~/modules/options/shareContainers/useOpbsProcessingState'
import { getStrikePriceAndType } from '~/utils/optionsTranslator'

import { useOpbsStrategyLogicState } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic'

const ChipsVerticalSpread = memo(function ChipsVerticalSpread() {
  const { state: sourceState } = useOpbsProcessingState.useContainer()
  const { state: strategyState, acts: strategyActs } = useOpbsStrategyLogicState.useContainer()

  /** 根據目前策略選擇，若為買權多頭或是買權空頭，顯示 callData */
  const source = useMemo(() => {
    return strategyState.strategy === 'PUT_SPREAD'
      ? sourceState.putData.map(datum => datum.symbol)
      : sourceState.callData.map(datum => datum.symbol)
  }, [sourceState.putData, sourceState.callData, strategyState.strategy])

  return (
    <div>
      <div
        css={css`
          height: 120px;
          overflow-y: auto;
          gap: 2px;
          .${chipClasses.root} {
            border-radius: 3px;
            cursor: pointer;
          }
        `}
      >
        {source.map(item => (
          <Chip
            size='small'
            onClick={() => {
              strategyActs.setPair(item)
            }}
            variant={'filled'}
            color={strategyState.pair === item ? 'error' : undefined}
            key={item}
            label={getStrikePriceAndType(item)}
          />
        ))}
      </div>
    </div>
  )
})

export default ChipsVerticalSpread
