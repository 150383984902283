import { memo } from 'react'
import { css } from '@emotion/react'
import { Chip } from '@mui/material'
import { useOpbsProcessingState } from '~/modules/options/shareContainers/useOpbsProcessingState'
import { getStrikePriceAndType, isCallOption } from '~/utils/optionsTranslator'

import { useOpbsStrategyLogicState } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic'
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

const ChipsStrangle = memo(function ChipsStrangle() {
  const { state, acts } = useOpbsStrategyLogicState.useContainer()
  const { state: uiState } = useVirtualExchangeCenterParametersState.useContainer()
  const { state: sourceState } = useOpbsProcessingState.useContainer()

  const isCall = isCallOption(uiState.currentSymbol)

  /** 已經先選買權，則取得所有可選賣權資料 */
  const source = isCall
    ? sourceState.putData.map(datum => ({
        symbol: datum.symbol,
        displayName: _convert(datum.symbol),
      }))
    : sourceState.callData.map(datum => ({
        symbol: datum.symbol,
        displayName: _convert(datum.symbol),
      }))

  return (
    <div
      css={css`
        height: 120px;
        overflow-y: auto;
      `}
    >
      {source.map(option => (
        <Chip
          size='small'
          onClick={() => {
            acts.setStrangleItem(option.symbol ?? '')
          }}
          variant={'filled'}
          color={state.strangleItem === option.symbol ? 'error' : undefined}
          key={option.symbol}
          label={getStrikePriceAndType(option.symbol)}
        />
      ))}
    </div>
  )
})

/** TXO19300P2 -> 19300 */
const _convert = (symbol: string) => {
  return getStrikePriceAndType(symbol)
}
export default ChipsStrangle
