import type { Option } from '~/modules/options/utils/Option'

/**
 * 用零碎資料組合回去選擇權商品名字
 *
 * @param isMonth -- 決定第3個字是O或是該月周次
 * @param contractDetails -- 決定第9個字「月份、買賣權」的字母，
 * @param price -- 像是17300，第4-8字
 * @param currentYear -- 決定最後一個字
 * @returns
 */
export const getOptionSymbol = (
  isMonth: boolean,
  contractDetails: Option.Contract | null,
  type: 'C' | 'P',
  price: number,
  currentYear: string,
) => {
  return (
    'TX' +
    (isMonth ? 'O' : contractDetails?.contractWeekAt) +
    price.toString().padStart(5, '0') +
    String.fromCharCode((contractDetails?.contractMonthAt ?? 1) + (type === 'C' ? 64 : 76)) +
    currentYear[currentYear.length - 1]
  )
}
