import getSymbolPrice from '~/modules/options/utils/getSymbolPrice'
import type { Option } from '~/modules/options/utils/Option'

/**
 * 計算時間價值：
 *
 * 賣權
 * - if (價內) 時間價值 = 現價 - (履約價 - 合成價格)
 * - else 等同於現價
 
 * 買權
 * - if (價內) 時間價值 = 現價 + (履約價 - 合成價格)
 * - else 等同於現價
 */
const getTimeValue = (
  item: Option.OpbsProcessedData | Option.ExtendValueOfOHLC,
  syntheticIndexPrice: number,
  isCall: boolean,
) => {
  const strikePrice = getSymbolPrice(item.symbol)
  let timeValue: number
  if (isCall)
    timeValue =
      strikePrice < syntheticIndexPrice
        ? item.close + (strikePrice - syntheticIndexPrice)
        : item.close
  else
    timeValue =
      strikePrice > syntheticIndexPrice
        ? item.close - (strikePrice - syntheticIndexPrice)
        : item.close

  timeValue = Math.round(timeValue * 100) / 100
  return { ...item, timeValue }
}

export default getTimeValue
