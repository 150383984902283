/**賣出跨式,SP,SC */
const SHORT_STRADDLE = 'SHORT_STRADDLE'

/**買進跨式：BP,BC */
const LONG_STRADDLE = 'LONG_STRADDLE'

/**賣出勒式 */
const SHORT_STRANGLE = 'SHORT_STRANGLE'

/**買進勒式 */
const LONG_STRANGLE = 'LONG_STRANGLE'

/**逆轉組合策略： BC, SP */
const REVERSAL = 'REVERSAL'

/**轉換組合策略： BP, SC */
const CONVERSION = 'CONVERSION'

/** 買權多頭價差：
 *
 * BC1 + SC2
 *
 * 履約商品：C2 > C1 (18500 > 18300)
 *
 * 賣履約高，買履約低
 * */
const BULL_CALL_SPREAD = 'BULL_CALL_SPREAD'

/** 賣權多頭價差：
 *
 * SP1 + BP2
 *
 * 履約商品：P1 > P2 (18500 > 18300)
 *
 * 賣履約高，買履約低
 * */
const BULL_PUT_SPREAD = 'BULL_PUT_SPREAD'

/** 買權空頭價差
 *
 * BC1 + SC2
 *
 * 履約商品：C1 > C2 (18900 > 18800)
 *
 * 買履約高，賣履約低
 * */
const BEAR_CALL_SPREAD = 'BEAR_CALL_SPREAD'

/** 賣權空頭價差：
 *
 * SP1 + BP2
 *
 * 履約商品：P2 > P1 (18900 > 18800)
 *
 * 買履約高，賣履約低
 * */
const BEAR_PUT_SPREAD = 'BEAR_PUT_SPREAD'

/* 跨式 */
const STRADDLE = 'STRADDLE'
const STRANGLE = 'STRANGLE'
const CALL_SPREAD = 'CALL_SPREAD'
const PUT_SPREAD = 'PUT_SPREAD'
const REVERSAL_CONVERSION = 'REVERSAL_CONVERSION'

export const generalStrategies = {
  STRADDLE,
  STRANGLE,
  CALL_SPREAD,
  PUT_SPREAD,
  REVERSAL_CONVERSION,
}

export const strategies = {
  SHORT_STRADDLE,
  LONG_STRADDLE,
  SHORT_STRANGLE,
  LONG_STRANGLE,
  REVERSAL,
  CONVERSION,
  BULL_CALL_SPREAD,
  BULL_PUT_SPREAD,
  BEAR_CALL_SPREAD,
  BEAR_PUT_SPREAD,
}

export const verticalSpreads = [CALL_SPREAD, PUT_SPREAD]
