import React, { memo, useState } from 'react'
import { css, Dialog, IconButton, paperClasses, Tooltip } from '@mui/material'
import { FcLineChart, FcBarChart } from 'react-icons/fc'
import { HiOutlineTable } from 'react-icons/hi'
import { globalGrey } from '~/modules/AppLayout/Colors'
import Statement from '~/modules/virtual-exchange-center/components/Statement'
import AccPnlContent from '~/modules/virtual-exchange-center/components/AccPnlContent'
import DailyPnlContent from '~/modules/virtual-exchange-center/components/DailyPnlContent'

const ChartDialogBtns = memo(function ChartDialogBtns() {
  const [statementDialogOpen, setStatementDialogOpen] = useState<boolean>(false)
  const [accPnlOpen, setAccPnlOpen] = useState<boolean>(false)
  const [dailyPnlOpen, setDailyPnlOpen] = useState<boolean>(false)
  return (
    <>
      {/* 對帳單 */}
      <Tooltip title={'對帳單'}>
        <IconButton
          onClick={() => setStatementDialogOpen(true)}
          color='secondary'
        >
          <HiOutlineTable />
        </IconButton>
      </Tooltip>
      <Dialog
        css={css`
          .${paperClasses.root} {
            height: 80vh;
            overflow-y: hidden;
            background: ${globalGrey.g100};
          }
        `}
        fullWidth
        maxWidth='xl'
        onClose={() => setStatementDialogOpen(false)}
        open={statementDialogOpen}
      >
        <Statement />
      </Dialog>
      {/* 對帳單 */}

      {/* 累積損益圖 */}
      <Tooltip title={'累積損益圖'}>
        <IconButton
          onClick={() => setAccPnlOpen(true)}
          color='secondary'
        >
          <FcLineChart />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth='xl'
        onClose={() => setAccPnlOpen(false)}
        open={accPnlOpen}
      >
        <AccPnlContent />
      </Dialog>
      {/* 累積損益圖 */}

      {/* 每日損益圖 */}
      <Tooltip title={'每日損益圖'}>
        <IconButton
          onClick={() => {
            setDailyPnlOpen(true)
          }}
          color='secondary'
        >
          <FcBarChart />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth='xl'
        onClose={() => setDailyPnlOpen(false)}
        open={dailyPnlOpen}
      >
        <DailyPnlContent />
      </Dialog>
      {/* 每日損益圖 */}
    </>
  )
})

export default ChartDialogBtns
