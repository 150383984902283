import { useCallback, useEffect, useState } from 'react'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'
import { OrderType, Trade } from '~/modules/SDK/Trade/Trade'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'

export type PriceType = 'M' | 'limit'
export type ModuleType = 'stock' | 'osfutures' | 'opbs' | 'self'
const templateTypes = ['grid', 'flat'] as const
type TemplateType = (typeof templateTypes)[number]
export const CallPutEnum = {
  B: '買進',
  S: '賣出',
}
export type CallPutKey = keyof typeof CallPutEnum

export type PositionColumns = keyof Trade.Position
export type TransactionColumns = keyof Trade.Transaction
export type OrderColumns = keyof Trade.Order

export type AcceptableColumn = PositionColumns | TransactionColumns | OrderColumns
const ORDERING_DIRECTIONS = ['asc', 'desc'] as const
export type OrderingDirection = (typeof ORDERING_DIRECTIONS)[number]

const ACCEPTABLE_COLUMNS: { key: string; value: AcceptableColumn; group: number[] }[] = [
  { key: '商品', value: 'symbol', group: [4, 1, 2, 3] },
  { key: '數量', value: 'qty', group: [4, 1, 2, 3] },
  { key: '均價', value: 'averagePrice', group: [4] },
  { key: '現價', value: 'marketPrice', group: [4] },
  { key: '損益', value: 'pnl', group: [4] },
  { key: '價格', value: 'price', group: [1, 2, 3] },
  { key: '手續費', value: 'commission', group: [1] },
  { key: '交易稅', value: 'tax', group: [1] },
  { key: '時間', value: 'datetime', group: [1] },
  // { key: 'bs', value: 'bs', group: [1, 2, 3] },
  { key: '建立時間', value: 'createdDatetime', group: [2, 3] },
  { key: '成交時間', value: 'dealDatetime', group: [2, 3] },
  // { key: '狀態', value: 'status', group: [2, 3] },
  // { key: '委託類型', value: 'orderType', group: [2, 3] },
  // { key: '倉位類型', value: 'positionType', group: [1, 2, 3] },
]

const useVirtualExchangeCenterParameters = () => {
  const [currentSymbol, setCurrentSymbol] = useState('')
  const [module, setModule] = useState<ModuleType>('osfutures')
  const [templateType, setTemplateType] = useState<TemplateType>('flat')
  const [column, setColumn] = useState<AcceptableColumn>('averagePrice')
  const [orderingDirection, setOrderingDirection] = useState<OrderingDirection>('asc')
  const [tabValue, setTabValue] = useState('4')

  /** Pop選項 start */
  const [formAnchorEl, setFormAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(formAnchorEl)
  /** Pop選項 end */

  /** 選擇權的格式比較多元 start */
  const [isOpbs, _setIsOpbs] = useState<boolean>(false)
  const [isCompoundOption, setIsCompoundOption] = useState<boolean>(false)
  /** 選擇權的格式比較多元 end */

  /** 由於平倉項目無法直接透過 realtime quote 取得現價，所以必須先暫存 start */
  const [isOffsetMode, _setIsOffsetMode] = useState<boolean>(false)
  const [tempOffsetPrice, setTempOffsetPrice] = useState<number>(0)
  /** 由於平倉項目無法直接透過 realtime quote 取得現價，所以必須先暫存 end */

  /** Order 選項 start */
  const [orderType, setOrderType] = useState<OrderType>('ROD')
  const [priceType, setPriceType] = useState<PriceType>('M')
  const [bs, setBs] = useState<CallPutKey>('B')
  /** Order 選項 end */

  /** Step 選項 start */
  const [step, setStep] = useState<number>(0)
  /** Step 選項 end */

  /** 使用者權限判斷 */
  // root, general user
  const user = useMeStore(s => s.meFirebaseState)
  const privateAccounts = useTradeStore(s => s.accounts)
  const currentAccountId = useTradeStore(s => s.currentSelectAccountId)
  const isUserQualified = !!privateAccounts.find(a => a.id === currentAccountId)
  const currentStrategyName = useTradeStore(s => s.currentStrategyName)
  /** 使用者權限判斷 */

  const isStrategyCenter = fr_agents.is['futuresai@strategy_center']
  const handleOpenForm = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
      symbol: string,
      isOpbsMode?: boolean,
      isOffsetNow?: boolean,
    ) => {
      /** Virtual_exchange: 他只是路過的，可能已經是 firebase user 但沒有開通交易帳戶 */
      if (!isStrategyCenter && !isUserQualified && event) return
      /** Strategy_center: 沒有登入 */
      if (isStrategyCenter && !currentStrategyName) return
      setFormAnchorEl(symbol && event ? event.currentTarget : null)
      setCurrentSymbol(symbol)
      useTradeStore.setState({ price: 'M' })
      setPriceType('M')
      useTradeStore.setState({ qty: 1 })
      setOrderType('ROD')
      /** 需要為true，一定要從外面的 UI 傳回來，否則都為 false */
      _setIsOpbs(isOpbsMode ?? false)
      _setIsOffsetMode(isOffsetNow ?? false)
    },
    [isUserQualified, currentStrategyName, isStrategyCenter],
  )

  useEffect(() => {
    /** Virtual_exchange: firebase user 沒有，代表沒登入 */
    if (!isStrategyCenter && !user) handleOpenForm(undefined, '')
    /** Strategy_center: 沒有登入 */
    if (isStrategyCenter && !currentStrategyName) handleOpenForm(undefined, '')
  }, [user, currentStrategyName, handleOpenForm])

  return {
    state: {
      currentSymbol,
      formAnchorEl,
      open,
      orderType,
      priceType,
      step,
      bs,
      module,
      templateType,
      column,
      orderingDirection,
      tabValue,
      isOpbs,
      isCompoundOption,
      isOffsetMode,
      tempOffsetPrice,
      isUserQualified,
    },
    props: {
      ACCEPTABLE_COLUMNS,
      ORDERING_DIRECTIONS,
    },
    acts: {
      setCurrentSymbol,
      setFormAnchorEl,
      handleOpenForm,
      setOrderType,
      setPriceType,
      setStep,
      setBs,
      setModule,
      setTemplateType,
      setColumn,
      setOrderingDirection,
      setTabValue,
      setIsCompoundOption,
      setTempOffsetPrice,
    },
  }
}

export const useVirtualExchangeCenterParametersState = createContainer(
  useVirtualExchangeCenterParameters,
)
