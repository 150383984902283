import { memo } from 'react'
import { css } from '@emotion/react'
import { ai, flex } from '~/modules/AppLayout/FlexGridCss'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { fontWeight600 } from '~/css/font'
import { globalGrey } from '~/modules/AppLayout/Colors'

const footerCss = css`
  padding: 16px 64px;
  ${flex.v.default};
  gap: 16px;
  ${ai.flexEnd};

  & p,
  & h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
`
const horizontalBoxCss = css`
  ${flex.h.crossCenter};
  gap: 16px;
  border-bottom: 1px solid ${globalGrey.g400};
`

const keyValueBoxCss = css`
  ${flex.h.crossCenter};
  font-size: 18px;
`
const keyCss = css`
  font-size: 16px;
  margin-right: 8px;
`

const valueCss = css`
  ${fontWeight600};
  font-size: 18px;
`
const StatementFooter = memo(function StatementFooter() {
  const statements = useTradeStore(s => s.statements)

  const buyLots = statements
    .map(
      s =>
        (s.openPositionQty > 0 ? s.openPositionQty : 0) +
        (s.closePositionQty > 0 ? s.closePositionQty : 0),
    )
    .reduce((s1, s2) => s1 + s2, 0)

  const sellLots = Math.abs(
    statements
      .map(
        s =>
          (s.openPositionQty < 0 ? s.openPositionQty : 0) +
          (s.closePositionQty < 0 ? s.closePositionQty : 0),
      )
      .reduce((s1, s2) => s1 + s2, 0),
  )

  const totalCommission = statements.map(s => s.commission).reduce((s1, s2) => s1 + s2, 0)
  const totalTax = statements.map(s => s.tax).reduce((s1, s2) => s1 + s2, 0)
  const totalProfit = statements.map(s => s.pnL).reduce((s1, s2) => s1 + s2, 0)

  return (
    <div css={footerCss}>
      <div css={horizontalBoxCss}>
        <div css={keyValueBoxCss}>
          <p css={keyCss}>買進量</p>
          <p css={valueCss}>{buyLots}</p>
        </div>
        <div css={keyValueBoxCss}>
          <p css={keyCss}>賣出量</p>
          <p css={valueCss}>{sellLots}</p>
        </div>
      </div>

      <div css={horizontalBoxCss}>
        <h4>小計</h4>
        <div css={keyValueBoxCss}>
          <p css={keyCss}>成交量</p>
          <p css={valueCss}>{buyLots}</p>
        </div>

        <div css={keyValueBoxCss}>
          <p css={keyCss}>手續費</p>
          <p css={valueCss}>{totalCommission}</p>
        </div>
        <div css={keyValueBoxCss}>
          <p css={keyCss}>交易稅</p>
          <p css={valueCss}>{totalTax}</p>
        </div>
        <div css={keyValueBoxCss}>
          <p css={keyCss}>淨損益</p>
          <p css={valueCss}>{totalProfit.toFixed()}</p>
        </div>
      </div>
    </div>
  )
})

export default StatementFooter
