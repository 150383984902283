import { memo, useCallback } from 'react'
import { css } from '@emotion/react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useLifecycles } from 'react-use'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useOTC50StockList, useTw50StockList } from '~/modules/SDK/Symbol/useCommonSymbolList'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import { Paper } from '@mui/material'
import { fontWeight600 } from '~/css/font'
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'
import { getDeltaColor } from '~/modules/virtual-exchange-center/utils/getDeltaColors'

export const StockWrapper = memo(function StockWrapper() {
  const tw50 = useTw50StockList()
  const otc50 = useOTC50StockList()
  const allSymbols = tw50?.concat(otc50 ?? [])

  return (
    <div
      css={css`
        ${flex.wrap.default};
        width: 100%;
        height: 40vh;
        overflow-y: auto;
        align-content: flex-start;
        padding: 16px 0;
        & > * {
          width: 120px;
          margin: 2px;
        }
      `}
    >
      {allSymbols?.map(os => (
        <Item_
          key={os}
          symbol={os}
        />
      ))}
    </div>
  )
})

export const Item_ = memo<
  ReactProps<{
    symbol: Signalr.SymbolString
    key: Signalr.SymbolString
  }>
>(function Item_({ symbol }) {
  const { acts } = useVirtualExchangeCenterParametersState.useContainer()
  const dictionary = useSymbolDictionaryStore(s => s.dictionary)
  useLifecycles(
    () => {
      useSignalrStore.getState().subscribeAdd([symbol], 'ohlc')
    },
    () => {
      useSignalrStore.getState().subscribeRemove([symbol], 'ohlc')
    },
  )
  const symbolInfo = useSignalrStoreValueOHLC(s => s.value[symbol])
  const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
  const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close)

  const handleSelectSymbol = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      acts.handleOpenForm(e, symbol)
    },
    [acts, symbol],
  )

  return (
    <Paper
      elevation={2}
      css={css`
        cursor: pointer;
        ${quoteAnimation}66;
      `}
    >
      <div
        onClick={handleSelectSymbol}
        css={css`
          padding: 4px 8px;
          color: ${getDeltaColor(quoteChanges?.closeChangePercent)};

          * {
            margin: 0;
          }
          & > *:nth-of-type(1) {
            font-size: 0.8rem;
            ${fontWeight600};
          }
          & > *:nth-of-type(2) {
            font-size: 1rem;
            ${fontWeight600};
          }
          & > *:nth-of-type(3) {
            font-size: 0.9rem;
          }
        `}
      >
        <div css={flex.h.crossCenter}>
          <p>{symbol}</p>
          <p> {dictionary[symbol]}</p>
        </div>
        <div>
          <p>{symbolInfo?.close}</p>
        </div>
        <div css={flex.h.default}>
          <h6>{quoteChanges.deltaChangeString}</h6>
          <h6> &nbsp;({quoteChanges.closeChangePercentString})</h6>
        </div>
      </div>
    </Paper>
  )
})
