import { TextField } from '@mui/material'
import { css } from '@emotion/react'
import { memo } from 'react'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'

const OrderQtyField = memo(function OrderQtyField() {
  const qty = useTradeStore(s => s.qty)
  return (
    <TextField
      css={css`
        width: 120px;
      `}
      size='small'
      label={'口數 1~499'}
      type='number'
      value={qty}
      onChange={event => {
        const n = parseInt(event.target.value)
        if (n <= 0 || n >= 500) return
        useTradeStore.setState({ qty: Number(event.target.value) })
      }}
    />
  )
})

export default OrderQtyField
