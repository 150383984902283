import { memo, useMemo } from 'react'
import { css } from '@emotion/react'
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid'
import type { Trade } from '~/modules/SDK/Trade/Trade'
import { expectType } from '~/utils/tsd'
import dayAPI from '~/utils/dayAPI'
import { globalGreen, globalRed } from '~/modules/AppLayout/Colors'
import { fontWeight600 } from '~/css/font'
import { localeDataGrid } from '~/configs/localeDataGrid'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { SymbolReadable } from '~/modules/SDK/Symbol/SymbolReadable'

const StatementTable = memo(function StatementTable() {
  const statements = useTradeStore(s => s.statements)
  const data: (Trade.Statement & { id: string })[] = useMemo(
    () =>
      statements.map(statement => ({
        ...statement,
        id: statement.datetime + statement.symbol + statement.pnL,
      })),
    [statements],
  )

  return (
    <DataGrid
      css={css`
        margin: 0 24px;
        background: white;
      `}
      rowHeight={32}
      rows={data}
      hideFooter={false}
      density='comfortable'
      localeText={localeDataGrid}
      columns={columns}
    />
  )
})

const priceRenderer = (params: GridCellParams) => {
  const value = parseFloat(params.value)
  const hasDecimal = value.toString().indexOf('.') !== -1
  return <p>{(params.value as number).toFixed(hasDecimal ? 2 : 0)}</p>
}

const columns: GridColDef[] = [
  {
    field: expectType<keyof Trade.Statement>('symbol'),
    headerName: '商品',
    flex: 3,
    renderCell: (params: GridCellParams) => (
      <p>
        {params.value}&nbsp;
        <SymbolReadable symbol={params.value} />
        {/* <SymbolName symbol={params.value}></SymbolName> */}
      </p>
    ),
  },
  {
    field: expectType<keyof Trade.Statement>('datetime'),
    headerName: '平倉時間',
    flex: 1.5,
    renderCell: (params: GridCellParams) => (
      <p>{dayAPI.utc(params.value).tz('Asia/Taipei').format('YYYY/MM/DD HH:mm')}</p>
    ),
  },
  {
    field: expectType<keyof Trade.Statement>('openPositionAveragePrice'),
    headerName: '開倉均價',
    flex: 1,
    renderCell: priceRenderer,
  },
  { field: expectType<keyof Trade.Statement>('openPositionQty'), headerName: '開倉數量', flex: 1 },
  {
    field: expectType<keyof Trade.Statement>('closePositionAveragePrice'),
    headerName: '平倉均價',
    flex: 1,
    renderCell: priceRenderer,
  },
  { field: expectType<keyof Trade.Statement>('closePositionQty'), headerName: '平倉數量', flex: 1 },
  {
    field: expectType<keyof Trade.Statement>('pnL'),
    headerName: '損益',
    flex: 1,
    renderCell: (params: GridCellParams) => {
      const value = parseInt(params.value)
      const color = value > 0 ? globalRed.r700 : globalGreen.g800
      return (
        <p
          css={css`
            color: ${color};
            ${fontWeight600};
          `}
        >
          {value}
        </p>
      )
    },
  },
  { field: expectType<keyof Trade.Statement>('commission'), headerName: '手續費', flex: 1 },
  { field: expectType<keyof Trade.Statement>('tax'), headerName: '交易稅', flex: 1 },
]

export default StatementTable
