import { css } from '@emotion/react'
import { IconButton, Button } from '@mui/material'
import { memo } from 'react'
import { MdDelete, MdRemoveCircle, MdWarning } from 'react-icons/md'
import { globalGreen, globalGrey, globalRed } from '~/modules/AppLayout/Colors'
import { flex, jc } from '~/modules/AppLayout/FlexGridCss'
import { OrderSatus, PositionType, Trade } from '~/modules/SDK/Trade/Trade'
import { tradeAPI } from '~/modules/SDK/Trade/tradeAPI'
import dayAPI from '~/utils/dayAPI'
import { isOpbsSymbol } from '~/utils/optionsTranslator'
import { toReadableNumber } from '~/utils/representChineseNumberFormat'
import { fontWeight600 } from '~/css/font'

import { SymbolReadable } from '~/modules/SDK/Symbol/SymbolReadable'
import { AuthTradeAPI } from '~/modules/SDK/Trade/AuthTradeAPI'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'

const getPositionType = (type: PositionType) => {
  switch (type) {
    case 'O':
      return '新倉'
    case 'C':
      return '平倉'
  }
}

const longerSymbolTextCss = css`
  flex: 1.5 !important;
`

export const TransactionFlatItem = memo<ReactProps<{ transaction: Trade.Transaction }>>(
  function TransactionFlatItem({ transaction }) {
    return (
      <div css={outerFlatCss}>
        <h5>{dayAPI.utc(transaction.datetime).local().format('MM/DD HH:mm:ss')}</h5>
        <h5 css={longerSymbolTextCss}>
          <SymbolReadable symbol={transaction.symbol} />
        </h5>
        <p>{transaction.bs}</p>
        <p>{transaction.qty}</p>
        <p>{transaction.price}</p>
        <p>{transaction.commission}</p>
        <p>{transaction.tax}</p>
        <p>{getPositionType(transaction.positionType)}</p>
      </div>
    )
  },
)

export const TransactionTileItem = memo<ReactProps<{ transaction: Trade.Transaction }>>(
  function TransactionTileItem({ transaction }) {
    const color = transaction.bs === 'B' ? globalRed.r700 : globalGreen.g800
    return (
      <div css={outerTileCss}>
        <h5>{dayAPI.utc(transaction.datetime).local().format('MM/DD HH:mm:ss')}</h5>
        <h5 css={longerSymbolTextCss}>
          <SymbolReadable symbol={transaction.symbol} />
        </h5>
        <p
          css={css`
            color: ${color};
            ${fontWeight600};
          `}
        >
          {transaction.bs}/{transaction.qty}
        </p>

        <p>{transaction.price}</p>
        <p>{transaction.commission}</p>
        <p>{transaction.tax}</p>
        <p>{getPositionType(transaction.positionType)}</p>
      </div>
    )
  },
)

type OffsetHandlerFunc = (
  event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  symbol: string,
  qty: number,
  price: number,
  isOpbs: boolean,
) => void

export const PositionFlatItem = memo<
  ReactProps<{
    position: Trade.Position
    offsetHandler: OffsetHandlerFunc
  }>
>(function PositionFlatItem({ position, offsetHandler }) {
  return (
    <div
      css={outerFlatCss}
      key={position.marketPrice}
    >
      <h5 css={longerSymbolTextCss}>
        <SymbolReadable symbol={position.symbol} />
      </h5>
      <h5>{position.qty}</h5>
      <p>{toReadableNumber(position.averagePrice)}</p>
      <p>{toReadableNumber(position.marketPrice)}</p>
      <p>{toReadableNumber(position.pnl)}</p>
      <div>
        <Button
          css={css`
            padding: 0;
            width: 80px;
          `}
          size='small'
          onClick={e => {
            offsetHandler(
              e,
              position.symbol,
              position.qty,
              position.marketPrice,
              isOpbsSymbol(position.symbol),
            )
          }}
        >
          平倉
        </Button>
      </div>
    </div>
  )
})

export const PositionTileItem = memo<
  ReactProps<{
    position: Trade.Position
    offsetHandler: OffsetHandlerFunc
  }>
>(function PositionTileItem({ position, offsetHandler }) {
  return (
    <div
      css={outerTileCss}
      key={position.marketPrice}
    >
      <div css={flex.h.allCenter}>
        <h5 css={longerSymbolTextCss}>
          <SymbolReadable symbol={position.symbol} />
        </h5>
      </div>
      <h5>{position.qty}</h5>
      <p>{toReadableNumber(position.averagePrice)}</p>
      <p>{toReadableNumber(position.marketPrice)}</p>
      <p>{toReadableNumber(position.pnl)}</p>
      <div>
        <Button
          size='small'
          css={css`
            padding: 0;
            width: 80px;
          `}
          onClick={e => {
            offsetHandler(
              e,
              position.symbol,
              position.qty,
              position.marketPrice,
              isOpbsSymbol(position.symbol),
            )
          }}
        >
          平倉
        </Button>
      </div>
    </div>
  )
})

const getStatus = (status: OrderSatus) => {
  switch (status) {
    case 'no_deal':
      return '未成交'
    case 'done':
      return '已成交'
    case 'settle_close':
      return '結算平倉'
    case 'cancel':
      return '取消'
    default:
      return '-'
  }
}
export const OrderFlatItem = memo<ReactProps<{ order: Trade.Order }>>(function OrderFlatItem({
  order,
}) {
  return (
    <div
      css={outerFlatCss}
      key={order.id}
    >
      <h5>{dayAPI.utc(order.createdDatetime).local().format('MM/DD HH:mm:ss')}</h5>
      <h5 css={longerSymbolTextCss}>
        <SymbolReadable symbol={order.symbol} />
      </h5>
      <h5>{order.bs}</h5>
      <p>{getStatus(order.status)}</p>
      <p>{order.price === 'M' ? '市價' : toReadableNumber(order.price)}</p>
      <p>{order.qty}</p>
      <div>
        <IconButton
          disabled={order.status !== null}
          onClick={() => {
            if (fr_agents.is['futuresai@strategy_center']) AuthTradeAPI.deleteOrder(order.id)
            else tradeAPI.deleteOrder(order.id)
          }}
          css={css`
            padding: 0;
            color: ${globalRed.r400};
          `}
          size='small'
        >
          {order.status !== null ? null : <MdDelete color={globalRed.r400} />}
        </IconButton>
      </div>
    </div>
  )
})

export const OrderTileItem = memo<ReactProps<{ order: Trade.Order }>>(function OrderTileItem({
  order,
}) {
  return (
    <div
      css={outerTileCss}
      key={order.id}
    >
      <h5>{dayAPI.utc(order.createdDatetime).local().format('MM/DD HH:mm:ss')}</h5>
      <h5 css={longerSymbolTextCss}>
        <SymbolReadable symbol={order.symbol} />
      </h5>
      <h5>{getStatus(order.status)}</h5>
      <div css={flex.h.allCenter}>
        <p>{order.price === 'M' ? '市價' : toReadableNumber(order.price)}</p>
        <p>/{order.qty}</p>
        <p>/{order.bs}</p>
      </div>
      <IconButton
        disabled={order.status !== null}
        onClick={() => {
          if (fr_agents.is['futuresai@strategy_center']) AuthTradeAPI.deleteOrder(order.id)
          else tradeAPI.deleteOrder(order.id)
        }}
        size='small'
        css={css`
          z-index: 1;
          position: absolute;
          right: -8px;
          top: -8px;
          padding: 0;
          color: ${globalRed.r400};
        `}
      >
        {order.status === 'done' ? null : order.status === 'settle_close' ? (
          <MdWarning color={globalGreen.g200} />
        ) : (
          <MdRemoveCircle />
        )}
      </IconButton>
    </div>
  )
})

export const TransactionFlatTemplate = memo(function TransactionFlatTemplate() {
  return (
    <div css={outerFlatCss}>
      <h5>時間</h5>
      <h5 css={longerSymbolTextCss}>商品</h5>
      <p>買賣別</p>
      <p>數量</p>
      <p>價格</p>
      <p>手續費</p>
      <p>稅</p>
      <p>倉別</p>
    </div>
  )
})

export const TransactionTileTemplate = memo(function TransactionTileTemplate() {
  return (
    <div css={outerTileCss}>
      <h5>時間</h5>
      <h5>商品</h5>
      <p>買賣別/數量</p>
      <p>價格</p>
      <p>手續費</p>
      <p>稅</p>
      <p>倉別</p>
    </div>
  )
})

export const PositionFlatTemplate = memo(function PositionFlatTemplate() {
  return (
    <div css={outerFlatCss}>
      <h5 css={longerSymbolTextCss}>商品</h5>
      <h5>數量</h5>
      <p>均價</p>
      <p>現價</p>
      <p>損益</p>
      <p>.</p>
    </div>
  )
})

export const PositionTileTemplate = memo(function PositionTileTemplate() {
  return (
    <div css={outerTileCss}>
      <div css={flex.h.allCenter}>
        <h5>商品</h5>
      </div>
      <h5>數量</h5>
      <p>均價</p>
      <p>現價</p>
      <p>損益</p>
      <p>.</p>
    </div>
  )
})

export const OrderFlatTemplate = memo(function OrderFlatTemplate() {
  return (
    <div css={outerFlatCss}>
      <h5>建立時間</h5>
      <h5 css={longerSymbolTextCss}>商品</h5>
      <h5>買賣別</h5>
      <h5>狀態</h5>
      <p>價格</p>
      <p>數量</p>
      <p>-</p>
    </div>
  )
})

export const OrderTileTemplate = memo(function OrderTileTemplate() {
  return (
    <div css={outerTileCss}>
      <h5>建立時間</h5>
      <h5>商品</h5>
      <h5>狀態</h5>
      <div css={flex.h.allCenter}>
        <p>價格</p>
        <p>/數量</p>
        <p>/買賣別</p>
      </div>
    </div>
  )
})

export const outerFlatCss = css`
  ${flex.h.crossCenter};
  ${jc.spaceBetween};
  width: 100%;
  border-bottom: 1px solid ${globalGrey.g400};
  padding: 8px 0;
  & > * {
    flex: 1;
  }
  p {
    margin: 0;
    font-size: 0.8rem;
  }
  h5 {
    margin: 0;
  }
  p:first-of-type {
    letter-spacing: -1px;
  }
`

export const outerTileCss = css`
  ${flex.v.crossCenter};
  border-radius: 8px;
  border: 1px solid ${globalGrey.g400};
  position: relative;
  padding: 4px;
  p {
    margin: 0;
    font-size: 0.8rem;
  }
  h5 {
    margin: 0;
  }
  p:first-of-type {
    letter-spacing: -1px;
  }
`
