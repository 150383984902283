import React, { memo } from 'react'
import StatementHeader from '~/modules/virtual-exchange-center/components/Statement/StatementHeader'
import StatementTable from '~/modules/virtual-exchange-center/components/Statement/StatementTable'
import StatementFooter from '~/modules/virtual-exchange-center/components/Statement/StatementFooter'

const Statement = memo(function Statement() {
  return (
    <>
      <StatementHeader />
      <StatementTable />
      <StatementFooter />
    </>
  )
})

export default Statement
