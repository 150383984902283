import { memo } from 'react'
import { Chip } from '@mui/material'
import { getStrikePriceAndType, isCallOption } from '~/utils/optionsTranslator'
import getSymbolPrice from '~/modules/options/utils/getSymbolPrice'
import { useOpbsProcessingState } from '~/modules/options/shareContainers/useOpbsProcessingState'

import { useOpbsStrategyLogicState } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic'
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

const ChipsStraddle = memo(function ChipsStraddle() {
  const { state, acts } = useOpbsStrategyLogicState.useContainer()
  const { state: sourceState } = useOpbsProcessingState.useContainer()
  const { state: uiState } = useVirtualExchangeCenterParametersState.useContainer()

  const isCall = isCallOption(uiState.currentSymbol)
  const correspondItem = (isCall ? sourceState.putData : sourceState.callData).find(
    item => getSymbolPrice(item.symbol) === getSymbolPrice(uiState.currentSymbol),
  )

  return (
    <div>
      <Chip
        size='small'
        onClick={() => {
          acts.setStraddleItem(correspondItem?.symbol ?? '')
        }}
        variant={'filled'}
        color={state.straddleItem === correspondItem?.symbol ? 'error' : undefined}
        key={correspondItem?.symbol}
        label={getStrikePriceAndType(correspondItem?.symbol)}
      />
    </div>
  )
})

export default ChipsStraddle
