import { css } from '@emotion/react'
import { Paper } from '@mui/material'
import { memo } from 'react'
import { fontWeight600 } from '~/css/font'
import { globalBlue } from '~/modules/AppLayout/Colors'
import { flex } from '~/modules/AppLayout/FlexGridCss'

export const ChipItem = memo<ReactProps<{ label: string; value?: number }>>(function ChipItem(
  props,
) {
  const formatter = new Intl.NumberFormat('en')

  return (
    <Paper
      css={css`
        ${flex.v.default};
        padding: 4px 2px;
        margin: 4px;
        ${fontWeight600};
      `}
    >
      <div
        css={css`
          ${flex.h.allCenter};
          border-radius: 4px;
          margin: 2px;
          padding: 2px 16px;
          font-size: 12px;
          min-width: 88px;
          background: ${globalBlue.b300};
          color: white;
        `}
      >
        {props.label}
      </div>
      <div
        css={css`
          font-size: 16px;
          align-self: center;
        `}
      >
        {formatter.format(Math.round(props.value ?? 0))}
      </div>
    </Paper>
  )
})
