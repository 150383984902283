import { Dayjs } from 'dayjs'
import useSWR from 'swr-0-5-6'
import { apirc } from '~/configs/apirc'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'

/** 實際上只有 open, high, low, close, volume, symbol 這6個 properties，為求方便直接 as Signalr.ValueOfOHLC */
export const useMarketPriceResource = (contract: string | undefined, to: Dayjs | undefined) => {
  const restfulApiURL = apirc.options.opQuotesURL.baseUrl
  const seconds = to?.unix()
  const res = useSWR<Signalr.ValueOfOHLC[]>(
    seconds ? `${restfulApiURL}?contract_month=${contract}&to=${seconds}` : null,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  )

  return res.data ?? []
}
