import { useState } from 'react'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'

/**暫時讓upper bound為undefined，目前不需要upper bound 參數 */
const Q_PAIRS: { key: string; value: [number, number | undefined] }[] = [
  { key: '不限', value: [1, undefined] },
  { key: '中', value: [5, undefined] },
  { key: '大', value: [10, undefined] },
  { key: '特大', value: [20, undefined] },
]

const useOptionMQParam = () => {
  const [paramQPair, setParamQPair] = useState<[number, number | undefined]>(Q_PAIRS[2].value)
  const [parameterM, setParameterM] = useState<number | undefined>(30000)

  return {
    state: {
      paramQPair,
      parameterM,
      Q_PAIRS,
    },
    acts: {
      setParamQPair,
      setParameterM,
    },
  }
}

/**
 * The state of conditions for filtering the data
 *
 * opbs, option-compare, option-price共用
 * */
export const useOptionMQParamState = createContainer(useOptionMQParam)
