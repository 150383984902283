import isEmpty from 'lodash/isEmpty'
import getSymbolPrice from '~/modules/options/utils/getSymbolPrice'
import type { Option } from '~/modules/options/utils/Option'

/**
 * 計算價平時間價值：
 * 取得既有的 `合成期貨價格`，之上下最接近的兩個履約價，買賣權各自兩檔
 *
 * @example
 * 合成期貨價格 17540，已知 17550 為價平，則要找的另外一檔是 17500
 * 因為 17540 落在 17500 - 17550 之間，而由於資料為降冪排列，因此要找的是 `index + 1`
 *
 * @example
 * 合成期貨價格 17724，已知17700 為價平，則要找的另外一檔是 17750
 * 17724 落在 17700 - 17750 之間，而由於資料為降冪排列，因此要找的是 `index - 1`
 */
const getAtThePriceAvgTimeValue = (
  callData: Option.OpbsProcessedData[] | Option.ExtendValueOfOHLC[],
  putData: Option.OpbsProcessedData[] | Option.ExtendValueOfOHLC[],
  syntheticPrice: number,
  atTheMoneyIndex: number,
) => {
  const haveEnoughData = !isEmpty(callData) && !isEmpty(putData)

  // 若沒有足夠資料 或是 價平索引為無效值
  if (!haveEnoughData || atTheMoneyIndex === -1) return 0
  const to =
    syntheticPrice > getSymbolPrice(callData[atTheMoneyIndex].symbol)
      ? (callData[atTheMoneyIndex - 1]?.timeValue ?? 0) +
        (putData[atTheMoneyIndex - 1]?.timeValue ?? 0)
      : (callData[atTheMoneyIndex + 1]?.timeValue ?? 0) +
        (putData[atTheMoneyIndex + 1]?.timeValue ?? 0)

  const from =
    (callData[atTheMoneyIndex]?.timeValue ?? 0) + (putData[atTheMoneyIndex]?.timeValue ?? 0)

  const timeValueNumerator = haveEnoughData ? from + to : 0
  return timeValueNumerator / 4
}

export default getAtThePriceAvgTimeValue
