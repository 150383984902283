import React from 'react'
import { css } from '@emotion/react'
import { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useOpbsStrategyLogicState } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic'

import OrderQtyField from '~/modules/virtual-exchange-center/components/OrderForm/OrderQtyField'
import OrderTypeToggle from '~/modules/virtual-exchange-center/components/OrderForm/OrderTypeToggle'
import StrategiesSelect from '~/modules/virtual-exchange-center/components/OrderForm/StrategiesSelect'
import ChipsStrangle from '~/modules/virtual-exchange-center/components/OrderForm/ChipsStrangle'
import ChipsVerticalSpread from '~/modules/virtual-exchange-center/components/OrderForm/ChipsVerticalSpread'
import ChipsStraddle from '~/modules/virtual-exchange-center/components/OrderForm/ChipsStraddle'
import ChipsReversalConversion from '~/modules/virtual-exchange-center/components/OrderForm/ChipsReversalConversion'
import BsToggle from '~/modules/virtual-exchange-center/components/OrderForm/BsToggle'
import PriceSettingField from '~/modules/virtual-exchange-center/components/OrderForm/PriceSettingField'

/** 選擇權複式 */
export const Form = memo<
  ReactProps<{
    tradeCb: () => void
    isOpbsMulti: boolean
  }>
>(function Form(props) {
  const { state: opbsState } = useOpbsStrategyLogicState.useContainer()

  return (
    <div
      css={css`
        ${flex.v.default};
        position: relative;
        width: ${ORDER_FORM_WIDTH}px;
        & > *:not(button) {
          margin-bottom: 16px;
        }
      `}
    >
      {props.isOpbsMulti && (
        <div>
          <StrategiesSelect />
          <div
            css={css`
              ${flex.h.crossCenter};
              margin: 8px;
              h5 {
                margin: 0;
              }
            `}
          >
            <h5>點選組合部位：</h5>
          </div>
          {opbsState.isStraddleStrategy && <ChipsStraddle />}
          {opbsState.isStrangleStrategy && <ChipsStrangle />}
          {opbsState.isVerticalSpreadStrategy && <ChipsVerticalSpread />}
          {opbsState.isReversalConversionStrategy && <ChipsReversalConversion />}
        </div>
      )}
      <div css={rowCss}>
        <PriceSettingField />
      </div>
      <div css={rowCss}>
        <BsToggle />
        <OrderQtyField />
      </div>
      <div css={rowCss}>
        <OrderTypeToggle />
      </div>
    </div>
  )
})

const ORDER_FORM_WIDTH = 360
const rowCss = css`
  ${flex.h.crossCenter};
  gap: 8px;
`
