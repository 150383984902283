import { memo } from 'react'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'

import {
  CallPutEnum,
  CallPutKey,
} from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

const BsToggle = memo(function BsToggle() {
  const { state, acts } = useVirtualExchangeCenterParametersState.useContainer()
  return (
    <ToggleButtonGroup size='small'>
      {Object.entries(CallPutEnum).map((s, index) => (
        <ToggleButton
          disabled={state.isOffsetMode}
          key={index}
          value={s[0]}
          onClick={e => {
            /** @ts-expect-error WHY：升上去nextjs@12, react@18 後壞了，暫不知何解 */
            acts.setBs(e.currentTarget.value as CallPutKey)
          }}
          /** OffsetEventHandler 會根據該部位目前的數量，自動設定 BS */
          selected={s[0] === state.bs}
        >
          {s[1]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
})

export default BsToggle
