import React, { memo } from 'react'
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState'
import { useOptionContractCodeState } from '~/modules/options/shareContainers/useOptionContractCodeState'
import { useOptionMQParamState } from '~/modules/options/shareContainers/useOptionMQParamState'
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState'
import { useOpenInterestState } from '~/modules/options/shareContainers/useOpenInterestState'
import { useOpbsProcessingState } from '~/modules/options/shareContainers/useOpbsProcessingState'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { SignalrTopic } from '~/modules/SDK/Signalr/SignalrTopic'

/** 內容與 option-price 的 Provider 雷同 */
const VirtualExchangeOpbsProvider = memo<ReactProps>(function VirtualExchangeOpbsProvider(props) {
  useSignalrStore.getState().subscribeAddTopic([SignalrTopic.tw_options])

  return (
    <useOptionDaysState.Provider>
      <useOptionContractCodeState.Provider>
        <useOptionMQParamState.Provider>
          {/* 以上 useOptionDaysState, useOptionContractCodeState, useOptionMQParamState 為參數層 */}
          <useOptionsSourceState.Provider>
            <useOpenInterestState.Provider>
              <useOpbsProcessingState.Provider>
                {/* 以上 useOptionsSourceState, useOpenInterestState, useOpbsProcessingState 為資料層 */}
                {props.children}
              </useOpbsProcessingState.Provider>
            </useOpenInterestState.Provider>
          </useOptionsSourceState.Provider>
        </useOptionMQParamState.Provider>
      </useOptionContractCodeState.Provider>
    </useOptionDaysState.Provider>
  )
})

export default VirtualExchangeOpbsProvider
