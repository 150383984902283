import { css } from '@emotion/react'
import { Select, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { memo } from 'react'

import {
  AcceptableColumn,
  useVirtualExchangeCenterParametersState,
} from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'

export const ColumnSelect = memo(function ColumnSelect() {
  const {
    state: uiState,
    props: uiProps,
    acts: uiActs,
  } = useVirtualExchangeCenterParametersState.useContainer()
  return (
    <Select
      size='small'
      css={css`
        width: 180px;
        margin-left: 16px;
        padding: 0;
      `}
      value={uiState.column}
      onChange={e => {
        uiActs.setColumn(e.target.value as AcceptableColumn)
      }}
    >
      {uiProps.ACCEPTABLE_COLUMNS.map(column => {
        return !column.group.find(g => g.toString() === uiState.tabValue) ? null : (
          <MenuItem
            key={column.value}
            value={column.value}
          >
            <div>{column.key}</div>
          </MenuItem>
        )
      })}
    </Select>
  )
})

export const AscOrDescSelect = memo(function AscOrDescSelect() {
  const { state: uiState, acts: uiActs } = useVirtualExchangeCenterParametersState.useContainer()
  return (
    <ToggleButtonGroup size='small'>
      <ToggleButton
        value={'asc'}
        onClick={() => {
          uiActs.setOrderingDirection('asc')
        }}
        selected={'asc' === uiState.orderingDirection}
      >
        asc
      </ToggleButton>
      <ToggleButton
        value={'desc'}
        onClick={() => {
          uiActs.setOrderingDirection('desc')
        }}
        selected={'desc' === uiState.orderingDirection}
      >
        desc
      </ToggleButton>
    </ToggleButtonGroup>
  )
})
